<template>
  <div class="wrapper">
    <div class="echarts" id="chartMap" ref="chartMap"></div>
    <p class="back" @click.stop="backTop" v-show="contentData.code !== '330100'">返回</p>
<!--    <div :class="[showMore ? 'large' : '', 'exceed_content']" @click="showMore = !showMore">-->
<!--      <dec-block-title>-->
<!--        街道信息展示-->
<!--        <span style="float:right">{{ showMore ? '点击关闭' : '点击展开' }}</span>-->
<!--      </dec-block-title>-->
<!--      <ul class="ul_head" v-show="showMore">-->
<!--        <li class="li_item">-->
<!--          <span class="item1">街道</span>-->
<!--          <span class="item1">监测点数</span>-->
<!--          <span class="item3">在线数</span>-->
<!--          <span class="item3">在线率</span>-->
<!--          <span class="item3">达标数</span>-->
<!--          <span class="item3">达标率</span>-->
<!--          <span class="item3">超标数</span>-->
<!--          <span class="item3">超标率</span>-->
<!--        </li>-->
<!--      </ul>-->
<!--      <ul class="ul_cont" v-show="showMore">-->
<!--        <li class="li_item" v-for="(item, index) in contentData.sumData.streetData" :key="index">-->
<!--          <span class="item1">{{ item.Name }}</span>-->
<!--          <span class="item1">{{ item.Number }}</span>-->
<!--          <span class="item3">{{ item.OnlineDeviceCount }}</span>-->
<!--          <span class="item3">{{ item.OnlinePercent }}</span>-->
<!--          <span class="item3">{{ item.NomalDeviceCount }}</span>-->
<!--          <span class="item3">{{ item.NomalPercent }}</span>-->
<!--          <span class="item3">{{ item.ExcessDeviceCount }}</span>-->
<!--          <span class="item3">{{ item.ExcessPercent }}</span>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { iconList } from '@/view/bigscreen/assets/util/index.js'
import decBlockTitle from '../../decorations/blockTitle2'

const infoWindow = new AMap.InfoWindow({
  isCustom: true,
  content: null,
  anchor: 'top-left',
  offset: new AMap.Pixel(16, -45),
  closeWhenClickMap: true
})

export default {
  name: 'dotMapDistrict',
  components: {
    decBlockTitle
  },
  props: {
    contentData: {
      type: Object,
      default: () => {
        return {
          areaId: null,
          code: ''
        }
      }
    },
    isShowEmphasisDot: {
      type: Object
    }
  },
  data() {
    return {
      myCharts: null,
      aliMap: null,
      map: {},
      parentJson: [],
      areaPath: [],
      geoJsonData: {},
      mapData: [],
      parentCode: ['100000'],
      config: {
        data: [],
        showValue: true,
        colors: ['#37a2da']
      },
      dotColor: ['#FF001A', '#78ff79', '#FEFF65', '#AEAEAE', '#000000'],
      dotsCollection: [],
      key: 1,
      showMore: false
    }
  },
  computed: {
    ...mapState(['topBoardData'])
  },
  methods: {
    initAliMap(areaCode) {
      const that = this
      that.aliMap = new AMap.Map('chartMap', {
        resizeEnable: true,
        mapStyle: 'amap://styles/db776e87dd712097ad645ad2fbf86a6b'
      })
      const polygon = new AMap.Polygon({
        map: that.aliMap,
        strokeWeight: 1,
        path: null,
        fillOpacity: 0.3,
        fillColor: '#CCF3FF',
        strokeColor: '#CC66CC'
      })
      // done用于district.search异步操作
      const done = function() {
        // 地图自适应
        that.aliMap.setFitView()
        that.drawAliMap()
      }
      let district
      AMap.plugin('AMap.DistrictSearch', function() {
        // 创建行政区查询对象
        district = new AMap.DistrictSearch({
          // 返回行政区边界坐标等具体信息
          extensions: 'all',
          // 设置查询行政区级别为 区
          level: 'district'
        })
      })
      district.search(areaCode, function(status, result) {
        // 获取区的边界信息
        polygon.setPath(result.districtList[0].boundaries)
        done()
      })
    },
    drawAliMap() {
      const that = this
      const deviceData = this.contentData.onlineDevice.onlineDeviceData || []
      const deviceDetail = this.contentData.onlineDevice.listDevice || []
      for (let i = 0; i < deviceData.length; i++) {
        const item = deviceData[i]
        const detail = deviceDetail[i]
        const r = this.dotColorAndStatusFormatter(deviceData[i].Status)
        // dotColorIndex = r[0], dotStatus = r[1]
        const dotColor = this.dotColor[r[0]]
        const scale = this.stoveNumFormatter(deviceDetail[i].StoveNum)
        const healthStatusColor = item.HealthCodeColor === 'Green' ? '#78ff79' : '#FF001A'
        const icon = this.iconFormatter(scale, r[0])
        const marker = new AMap.Marker({
          position: [item.Lng, item.Lat],
          map: that.aliMap,
          zIndex: 1,
          // icon: item.HealthCodeColor === 'Green' ? greenCircleIcon : redCircleIcon,
          icon: icon,
          extData: {
            Status: item.Status,
            HealthCodeColor: item.HealthCodeColor,
            colorIndex: r[0],
            scale: scale
          }
        })
        that.dotsCollection.push(marker)
        AMap.event.addListener(marker, 'click', () => {
          infoWindow.setContent(`
          <div style="border:1px solid #03608C;padding:8px 16px;background-color:#011015;opacity:0.8">
            <ul style="list-style:none;margin: 0;padding:0;font-size:15px;">
              <li>
                <p>
                  <span style="color:#B2F2F6;">店铺名称：</span>
                  <span style="color:#67e0e3;">${detail.CustomerName}</span>
                </p>
              </li>
              <li>
                <div style="display:inline-block;color:#B2F2F6;">实时状态：</div>
                <div style="width:10px;height:10px;background-color:${dotColor};display:inline-block;border-radius:50%;margin-right:5px"></div>
                <span style="color:#67e0e3">(${r[1]})</span>
                <div style="display:inline-block;color:#B2F2F6;">排放状态码：</div>
                <div style="width:10px;height:10px;background-color:${healthStatusColor};display:inline-block;border-radius:50%;margin-right:5px"></div>
              </li>
              <li>
                <p>
                  <span style="color:#B2F2F6">店铺规模：</span>
                  <span style="color:#67e0e3">${scale}</span>
                  <span style="margin-left:4px;color:#B2F2F6">灶头数：</span>
                  <span style="color:#67e0e3">${deviceDetail[i].StoveNum}</span>
                </p>
              </li>
              <li>
                <p>
                  <span style="color:#B2F2F6;">联系人：</span>
                  <span style="color:#67e0e3;">${detail.CustomerContact}</span>
                  <span style="margin-left:4px;color:#B2F2F6;">联系电话：</span>
                  <span style="color:#67e0e3;">${detail.CustomerMobile}</span>
                </p>
              </li>
              <li>
                <h5 style="color:#B2F2F6;margin:5px 0 0;">监测点信息</h5>
              </li>
              <li>
                <p>
                  <span style="color:#B2F2F6;">监测点名称：</span>
                  <span style="color:#67e0e3;">${detail.LocaleName}</span>
                </p>
              </li>
              <li>
                <p>
                  <span style="color:#B2F2F6;">菜系：</span>
                  <span style="color:#67e0e3;">${detail.CuisineName}</span>
                </p>
              </li>
              <li>
                <p>
                  <span style="color:#B2F2F6;">地址：</span>
                  <span style="color:#67e0e3;">${detail.Addr}</span>
                </p>
              </li>
              <li>
                <p>
                  <span style="color:#B2F2F6;">设备：</span>
                  <span style="color:#67e0e3;">${detail.MN}</span>
                </p>
              </li>
              <li>
                <div>
                  <a style="color:#67e0e3;margin-right: 4px;" href="${document.URL.split('board')[0] + 'Notification?MN=' + deviceDetail[i].MN + '&type=0'}" target="_blank" rel="opener">一点一档</a>
                  <a style="color:#67e0e3;" href="${document.URL.split('board')[0] + 'Notification?LocaleId=' + deviceDetail[i].Id + '&Owner='+ deviceDetail[i].Owner + '&type=1'}" target="_blank" rel="opener">报警信息</a>
                </div>
              </li>
            </ul>
          </div>
        `)
          infoWindow.open(that.aliMap, marker.getPosition())
        })
        marker.setMap(that.aliMap)
      }
    },
    iconFormatter(scale, colorIndex) {
      switch (scale) {
        case '大':
          return iconList.big[colorIndex]
        case '中':
          return iconList.medium[colorIndex]
        case '小': {
          return iconList.small[colorIndex]
        }
      }
    },
    stoveNumFormatter(StoveNum) {
      let r
      if (StoveNum > 6) {
        r = '大'
      } else if (StoveNum > 3) {
        r = '中'
      } else {
        r = '小'
      }
      return r
    },
    dotColorAndStatusFormatter(Status) {
      let r = []
      switch (Status) {
        case 'NORMAL':
          r = [1, '达标']
          break
        case 'EXCESS':
          r = [0, '超标']
          break
        case 'DOWN':
          r = [3, '离线']
          break
        case 'OFF':
          r = [4, '异常离线']
          break
        case 'ALARM':
          r = [1, '预警']
          break
        default:
          r = [4, '未知状态']
      }
      return r
    },
    getData() {
      const installData =
        this.contentData.countyLevelData.deviceInstalledYesterday || []
      const complianceData =
        this.contentData.countyLevelData.complianceYesterday || []
      const dataMap = complianceData.map((item) => {
        installData.map((vo) => {
          if (item.K.split('/').join('') === vo.K.split('/').join('')) {
            item.name = vo.N
            item.value = item.V
          }
        })
        return item
      })
      this.$set(this.config, 'data', dataMap)
      this.key++
    },
    boardDataChange(data) {
      this.$store.commit('setNewBoardData', { res: data, dataObj: this.contentData })
    },
    backTop() {
      this.$store.dispatch('popNewBoardData')
    },
  },
  watch: {
    contentData: {
      handler: function(data) {
        if (data.level === 'district') {
          const quCode = data.code
          this.initAliMap(quCode)
        }
      }
    },
    'isShowEmphasisDot.show': {
      handler: function(val) {
        if (this.dotsCollection) {
          if (val) {
            this.dotsCollection.forEach(marker => {
              const status = marker.getExtData().Status
              if (status === 'EXCESS') {
                marker.setIcon(iconList.exceed)
              } else {
                marker.hide()
              }
            })
          } else {
            this.dotsCollection.forEach(marker => {
              const status = marker.getExtData().Status
              if (status === 'EXCESS') {
                const scale = marker.getExtData().scale
                const colorIndex = marker.getExtData().colorIndex
                const icon = this.iconFormatter(scale, colorIndex)
                marker.setIcon(icon)
              }
              marker.show()
            })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.qu_capsule {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 200px;
  height: 28vh;
  background: rgba(3, 96, 140, 0.4);
  .dv-capsule-chart {
    padding: 5px 20px 5px 5px !important;
  }
}
.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}
.back {
  position: absolute;
  left: 2%;
  top: 2%;
  color: #eee;
  z-index: 99999;
  cursor: pointer;
}
.large {
  min-width: 400px !important;
  .hide:hover {
    cursor: pointer;
  }
}
.exceed_content {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  color: #67e0e3;
  min-width: 200px;
  background: #011d24;
  border: solid 1px rgb(3, 96, 140);
  .ul_head {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #67e0e3;
    }
    .li_item {
      display: flex;
      justify-content: space-around;
      height: 4vh;
      line-height: 4vh;
      span {
        text-align: center;
        display: inline-block;
      }
      .item1 {
        width: 40%;
      }
      .item2,
      .item3 {
        width: 30%;
      }
    }
  }
  .ul_cont {
    margin: 0;
    padding: 0;
    height: 28vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #67e0e3;
    }
    .li_item {
      display: flex;
      justify-content: space-around;
      height: 4vh;
      line-height: 4vh;
      span {
        text-align: center;
        display: inline-block;
      }
      .item1 {
        width: 40%;
      }
      .item2,
      .item3 {
        width: 30%;
      }
    }
  }
}
.exceed_content:hover {
  cursor: pointer;
}
</style>
